import { useEffect, useMemo, useState } from 'react'
import {
  DEVICE_MEDIA_QUERY,
  DEVICE_SIZE_VARIANT,
} from 'shared/utils/deviceSize'

const useMediaQueryBySize = (deviceSize: DEVICE_SIZE_VARIANT): boolean => {
  const query = useMemo(
    () =>
      window.matchMedia(DEVICE_MEDIA_QUERY[deviceSize as DEVICE_SIZE_VARIANT]),
    [deviceSize],
  )
  const [matches, setMatches] = useState<boolean>(query?.matches)

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => setMatches(e.matches)

    query.addEventListener('change', handleChange)
    return () => query.removeEventListener('change', handleChange)
  }, [deviceSize])

  return matches
}

const useMediaQuery = () => {
  const isMobile = useMediaQueryBySize('mobile')
  const isTablet = useMediaQueryBySize('tablet')
  const isLaptop = useMediaQueryBySize('laptop')
  const isDesktop = useMediaQueryBySize('desktop')

  return { isDesktop, isLaptop, isMobile, isTablet }
}

export default useMediaQuery
