import { lazy } from 'react'
import { ROUTES } from './paths'

const homePageComponent = lazy(() =>
  import('pages/Home').then((module) => ({
    default: module.default,
  })),
)

const registerPageComponent = lazy(() =>
  import('pages/Register').then((module) => ({
    default: module.default,
  })),
)

const appsPageComponent = lazy(() =>
  import('pages/Apps').then((module) => ({
    default: module.default,
  })),
)

const contactUsPageComponent = lazy(() =>
  import('pages/ContactUs').then((module) => ({
    default: module.default,
  })),
)

const integrationsPageComponent = lazy(() =>
  import('pages/Integrations').then((module) => ({
    default: module.default,
  })),
)

const signInPageComponent = lazy(() =>
  import('pages/SignIn').then((module) => ({
    default: module.default,
  })),
)

const checkYourInbox = lazy(() =>
  import('pages/CheckYourInbox').then((module) => ({
    default: module.default,
  })),
)
const recoveryPasswordComponent = lazy(() =>
  import('pages/RecoveryPassword').then((module) => ({
    default: module.default,
  })),
)

const signUpRedirect = lazy(() =>
  import('pages/Register/RegisterRedirect').then((module) => ({
    default: module.default,
  })),
)

const routePathToComponentMapper = {
  [ROUTES.APPS]: appsPageComponent,
  [ROUTES.BASE]: homePageComponent,
  [ROUTES.CHECK_INBOX]: checkYourInbox,
  [ROUTES.CONTACT_US]: contactUsPageComponent,
  [ROUTES.INTEGRATIONS]: integrationsPageComponent,
  [ROUTES.LOGIN]: signInPageComponent,
  [ROUTES.RECOVERY_PASSWORD]: recoveryPasswordComponent,
  [ROUTES.SIGNUP]: signUpRedirect,
  [ROUTES.SIGNUP_STEP]: registerPageComponent,
}

export const routesList = Object.entries(routePathToComponentMapper).map(
  ([route, component]) => ({
    Component: component,
    path: route,
  }),
)
