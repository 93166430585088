import { useCallback, useState, createContext, ReactNode } from 'react'
import { SnackbarTypes } from '@web-ui-kit/snackbar'
import SnackbarFeedback from 'shared/components/SnackbarFeedback'

const SNACKBAR_TIMEOUT = 10000
export type Snackbar = {
  text: string
  type: SnackbarTypes
}

export const SnackbarContext =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createContext((snackbar: Snackbar) => {
    /* this is a empty function */
  })

type SnackbarContextProviderProps = {
  children: ReactNode
}

const SnackbarContextProvider = ({
  children,
}: SnackbarContextProviderProps) => {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([])

  const addSnackbar = useCallback(
    (snackbar: Snackbar) => {
      setSnackbars((snackbars) => [...snackbars, snackbar])
      setTimeout(
        () => setSnackbars((snackbars) => snackbars.slice(1)),
        SNACKBAR_TIMEOUT,
      )
    },
    [setSnackbars],
  )

  return (
    <SnackbarContext.Provider value={addSnackbar}>
      {children}
      {Boolean(snackbars.length) && <SnackbarFeedback snackbars={snackbars} />}
    </SnackbarContext.Provider>
  )
}

export default SnackbarContextProvider
