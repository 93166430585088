import i18n, { use } from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    debug: false,
    fallbackLng: localStorage.getItem('userLanguage') || 'es',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    nsSeparator: false,
  })

export default i18n
