import { usePortal } from '@web-ui-kit/hooks.use-portal'
import { v4 as uuidv4 } from 'uuid'
import Snackbar from '@web-ui-kit/snackbar'
import * as Sc from './SnackbarFeedback.style'
import useMediaQuery from 'shared/hooks/useMediaQuery'
import { Snackbar as SnackbarType } from 'shared/contexts/SnackbarContext'

type SnackbarFeedbackProps = {
  snackbars: SnackbarType[]
}

const SnackbarFeedback = ({ snackbars }: SnackbarFeedbackProps) => {
  const { isMobile } = useMediaQuery()
  const { render } = usePortal('snackbar-feedback')

  return render(
    <Sc.Container>
      {snackbars.map(({ text, type }) => (
        <Snackbar
          key={uuidv4()}
          size={isMobile ? 'small' : 'large'}
          text={text}
          type={type}
        />
      ))}
    </Sc.Container>,
  )
}

export default SnackbarFeedback
