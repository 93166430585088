import { styled } from 'styled-components'
import SPACES from '@web-ui-kit/spaces'
import { DEVICE_SIZE } from 'shared/utils/deviceSize'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${SPACES.XS};
  position: fixed;
  top: ${SPACES['2XL']};
  width: 100%;
  @media (width > ${DEVICE_SIZE.LAPTOP}) {
    top: 5.625rem;
  }
  @media (width > ${DEVICE_SIZE.TABLET}) {
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
  }
`
