import Loader from '@ui-components/loader'
import * as Sc from './Spinner.style'

const Spinner = () => {
  return (
    <Sc.SpinnerBox>
      <Loader />
    </Sc.SpinnerBox>
  )
}

export default Spinner
