/* eslint-disable @typescript-eslint/no-explicit-any */
import { getWebInstrumentations } from '@grafana/faro-react'
import { faro } from '@grafana/faro-web-sdk'
import { v4 as uuidv4 } from 'uuid'
import { IUser } from '../types'

const { REACT_APP_API_KEY_GRAFANA_FARO } = process.env

const { enabledGrafanaFaro, enabledGrafanaFaroPerformanceInstrumentation } =
  (window as any).grafana || {}
const { grafanaFaroEnvironment, grafanaFaroUrl } = (window as any).eduBook || {}
const setGrafanaUuidDevice = () => {
  const nameItem = 'uuidDevice'
  let uuidDevice = localStorage.getItem(nameItem)
  if (!uuidDevice) {
    uuidDevice = uuidv4()
    localStorage.setItem(nameItem, uuidDevice)
  }
  return uuidDevice
}
export const grafanaUtils = {
  getInitialOptions() {
    const initialOptions = {
      apiKey: REACT_APP_API_KEY_GRAFANA_FARO,
      app: {
        environment: grafanaFaroEnvironment,
        name: 'edubook_learning_public',
      },
      batching: {
        enabled: true,
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          enablePerformanceInstrumentation:
            enabledGrafanaFaroPerformanceInstrumentation,
        }),
      ],
      session: (window as any).__PRELOADED_STATE__?.faro?.session,
      url: grafanaFaroUrl,
    }
    return initialOptions
  },
  pushEvent(name: string, attributes: Record<string, string>) {
    if (!enabledGrafanaFaro) {
      return
    }
    faro.api.pushEvent(name, {
      ...attributes,
      uuidDevice: setGrafanaUuidDevice(),
    })
  },
  setUser(username: IUser['username']) {
    if (!enabledGrafanaFaro) {
      return
    }
    faro.api.setUser({
      attributes: {
        username,
        uuidDevice: setGrafanaUuidDevice(),
      },
      id: setGrafanaUuidDevice(),
      username,
    })
  },
  setView(view: string) {
    if (!enabledGrafanaFaro) {
      return
    }
    faro.api.setView({ name: view })
  },
}
