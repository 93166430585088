/* eslint-disable sort-keys */
// The values indicates the maximum width of the screen device.
// Example: if the device width is less than or equal to 1024px, it is considered as a laptop
// 1025px and more is considered as a desktop screen
export const DEVICE_SIZE = {
  LAPTOP: '1024px',
  TABLET: '745px',
  MOBILE: '430px',
}

export type DEVICE_SIZE_VARIANT = 'desktop' | 'laptop' | 'tablet' | 'mobile'

const BREAKPOINTS: {
  [key in DEVICE_SIZE_VARIANT]: { max: number; min: number }
} = {
  desktop: {
    max: 9999,
    min: parseInt(DEVICE_SIZE.LAPTOP) + 1,
  },
  laptop: {
    max: parseInt(DEVICE_SIZE.LAPTOP),
    min: parseInt(DEVICE_SIZE.TABLET) + 1,
  },
  tablet: {
    max: parseInt(DEVICE_SIZE.TABLET),
    min: parseInt(DEVICE_SIZE.MOBILE) + 1,
  },
  mobile: {
    max: parseInt(DEVICE_SIZE.MOBILE),
    min: 0,
  },
}

export const DEVICE_MEDIA_QUERY = {
  desktop: `(min-width: ${BREAKPOINTS.desktop.min}px) and (max-width: ${BREAKPOINTS.desktop.max}px)`,
  laptop: `(min-width: ${BREAKPOINTS.laptop.min}px) and (max-width: ${BREAKPOINTS.laptop.max}px)`,
  tablet: `(min-width: ${BREAKPOINTS.tablet.min}px) and (max-width: ${BREAKPOINTS.tablet.max}px)`,
  mobile: `(min-width: ${BREAKPOINTS.mobile.min}px) and (max-width: ${BREAKPOINTS.mobile.max}px)`,
}
