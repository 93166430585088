const SIGNUP_BASIC_URL = '/:lang/signup'

export const ROUTES = {
  APPS: '/:lang/apps',
  BASE: '/:lang/',
  CHECK_INBOX: '/:lang/check-your-inbox',
  CONTACT_US: '/:lang/contact-us',
  INTEGRATIONS: '/:lang/integrations',
  LOGIN: '/:lang/login',
  RECOVERY_PASSWORD: '/:lang/recovery-password',
  SIGNUP: SIGNUP_BASIC_URL,
  SIGNUP_STEP: `${SIGNUP_BASIC_URL}/:step`,
} as const

export type ROUTE_PATHS = (typeof ROUTES)[keyof typeof ROUTES]
